import './App.css';
import {React } from 'react';
import Footer from './component/_Footer';
import Header from './component/_header';
import Home from './pages/Home';
import { Routes, Route } from "react-router-dom";
import Portfolio from './pages/Portfolio';
import Contact from './pages/Contact';
import Blog from './pages/Blog';
import Posts from './pages/SinglePost';
import NotFound from './pages/NotFound';
import { Analytics } from "@vercel/analytics/react";
import BottomNav from './component/_BottomNav';


function App() {

  // useEffect(() => {
  //   // Disable right-click context menu
  //   const disableContextMenu = (e) => {
  //     e.preventDefault();
  //   };

  //   // Disable specific keyboard shortcuts
  //   const disableKeyboardShortcuts = (e) => {
  //     // F12, Ctrl+Shift+I, Ctrl+Shift+J, Ctrl+U, Ctrl+S
  //     if (
  //       e.keyCode === 123 || // F12
  //       (e.ctrlKey && e.shiftKey && e.keyCode === 73) || // Ctrl+Shift+I
  //       (e.ctrlKey && e.shiftKey && e.keyCode === 74) || // Ctrl+Shift+J
  //       (e.ctrlKey && e.keyCode === 85) || // Ctrl+U
  //       (e.ctrlKey && e.keyCode === 83) // Ctrl+S
  //     ) {
  //       e.preventDefault();
  //     }
  //   };

  //   document.addEventListener('contextmenu', disableContextMenu);
  //   document.addEventListener('keydown', disableKeyboardShortcuts);

  //   // Cleanup event listeners on component unmount
  //   return () => {
  //     document.removeEventListener('contextmenu', disableContextMenu);
  //     document.removeEventListener('keydown', disableKeyboardShortcuts);
  //   };
  // }, []);

  return (
    <div className="Main__App">
      <Header/>
      <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="contact-us" element={<Contact />} />
          <Route path="*" element={<NotFound />} />
          <Route path="portfolio" element={<Portfolio/>} />
          <Route path="/Blog" element={<Blog />} />
          <Route path="/blog/:postId/:postTitle" element={<Posts />} />
      </Routes>
      <BottomNav/>
    <Footer />
    <Analytics />
  </div>
  );
}

export default App;
