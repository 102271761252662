import React from 'react';
import GithubBtn from '../component/_GithubBtn'
import '../style/_GithubBanner.css'

const GithubBanner = () => {
  return (
    <div className='github__banner'>
      <div>
      <h2>Here's my &nbsp;&nbsp;&nbsp;<GithubBtn/></h2>
      </div>
    </div>
  )
}

export default GithubBanner
