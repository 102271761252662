import React from "react";
import MapComponent from "../component/_Map";
import Contact from "../component/_Contact";
import "../style/Contact.css";
import ContactInfo from "../component/_ContactInfo";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  return (
    <div>
      <Helmet>
        <title>Contact Us - Suraj Shekhawat</title>
        <meta
          name="description"
          content="Get in touch with suraj shekhawat, a professional software developer. Contact for inquiries, project collaborations, and more."
        />
        <meta
          name="keywords"
          content="Contact, Suraj Shekhawat, software developer, inquiries, collaborations"
        />
        <meta name="author" content="Suraj Shekhawat" />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://surajshekhawat.vercel.app/" />
        <meta property="og:title" content="Contact Us - Suraj Shekhawat Portfolio" />
        <meta property="og:description" content="Get in touch with Suraj Shekhawat, a professional software developer. Contact for inquiries, project collaborations, and more." />
        <meta
          property="og:image"
          content="https://i.ibb.co/0sSkDvy/profile-img.png"
        />

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://x.com/Suraj____07" />
        <meta property="twitter:title" content="Contact Us - Suraj Shekhawat Portfolio" />
        <meta property="twitter:description" content="Get in touch with Suraj Shekhawat, a professional software developer. Contact for inquiries, project collaborations, and more." />
        <meta
          property="twitter:image"
          content="https://pbs.twimg.com/profile_images/1705881145257472000/mmr6cOKo_200x200.jpg"
        />

      </Helmet>

      <div className="container Contact__detail my-14">
        {/* <h1>Resume</h1>   */}
        <h2>
          <span style={{ color: "#892CDC" }}>C</span>ontact
        </h2>
        <hr size="10" />
        <p>Please Explain Your Project Details To Us</p>

        <div className="my-8">
          <ContactInfo />
        </div>
      </div>

      {/* ============ MAP / CONTACT =========== */}
      <div class="container mx-auto p-4">
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/2 p-4 flex items-center">
            <MapComponent /> {/* COPONENT */}
          </div>

          <div class="w-full md:w-1/2 p-4">
            <Contact /> {/* COPONENT */}
          </div>
        </div>
      </div>
      {/* =========== MAP / CONTACT / END ============ */}
    </div>
  );
};

export default ContactUs;
