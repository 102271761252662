import React from 'react';
import '../style/_Resume.css';

const Resume = () => {
  return (
    <div id='Resume' className='container resume__detail my-14'>
      {/* <h1>Resume</h1>   */}
      <h2><span style={{color: '#892CDC'}}>R</span>esume</h2>
      <hr size="10"/>
      <p>Experienced AngularJs, ReactJs and Wordpress Developer at Appinventers, proficient in PHP and skilled in jQuery AJAX. Specialized in crafting visually appealing webpages using HTML, CSS, Tailwind, Bootstrap, and Material UI. Possesses a strong foundation in Python, C++, C, and Java, mongoDB, Javascript with expertise in SQL. Eager to apply expertise to drive innovative web development projects forward.</p>
    </div>
  )
}

export default Resume
