import React from "react";

const ResumeBtn = () => {
  return (
    <div className="flex justify-center">
    <a
      href="https://drive.google.com/file/d/19Hp7g0aS8YF5D14fEv_ZwQjfVQhzN7OK/view?usp=sharing"
      target="_blank"
    >
      <button class="bg-gradient-to-r from-purple-500 to-purple-700 hover:from-purple-600 hover:to-purple-800 text-white font-bold py-3 px-6 rounded-full shadow-lg transform transition-all duration-500 ease-in-out hover:scale-110 hover:brightness-110 hover:animate-pulse active:animate-bounce">
        Download CV 
      </button>
    </a>
    </div>
  );
};

export default ResumeBtn;
