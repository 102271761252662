// src/theme.js
export const darkTheme = {
  text: '#FFFFFF',
  toggleBorder: '#6B8096',
  background: '#000000',
  shadow: '6px 6px 12px #141414, -6px -6px 12px #000000',
  navborder: '1px solid rgba(0, 0, 0, 0.3)',
  navBg: 'rgba(0, 0, 0, 0.2)',
  navShadow: '0 4px 30px rgba(0, 0, 0, 0.1)' ,
  cardBg: '#0000',
  CardShadow: 'rgba(76, 76, 76, 0.2) 0px 8px 24px',
};


export const lightTheme = {
    text: '#000000',
    toggleBorder: '#6B8096',
    background: '#f1f1f1',
    shadow: '6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff',
    navborder: '1px solid rgba(255, 255, 255, 0.3)',
    navBg: 'rgba(255, 255, 255, 0.2)',
    navShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
    cardBg: '#f1f1f1',
    CardShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
  };
  
