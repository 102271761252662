import React from "react";
import HeroComponent from "../component/_HeroComponent";
import AboutMe from "../component/_AboutMe";
import Resume from "../component/_Resume";
import Experience from "../component/_Experience";
import Education from "../component/_Education";
import GithubBanner from "../component/_GithubBanner";
import { Element } from "react-scroll";
// import Contact from "../component/_Contact";
// import Contact from "./Contact";
import { Helmet } from "react-helmet";

import ContactComponent from "../component/_ContactComponent";

const Home = () => {
  return (
    <div>
      <Helmet>
        <title>Home -  Suraj Shekhawat</title>
        <meta
          name="description"
          content="Suraj Shekhawat is a professional software developer. He is passionate about web development and design. He is currently working as a full-stack web developer."
        />
        <meta
          name="keywords" content="suraj shekhawat, suraj singh shekhawat, suraj veer singh shekhawat, mern stack developer,  wordpress developer, reactJs developer, angulaJs developer, shopify developer, appinventive, appinventiv, freelancer" />
      </Helmet>
      <Element name="home">
        <HeroComponent />
      </Element>

      <Element name="about">
        <AboutMe />
      </Element>

      <Element name="experience">
        <Resume />
        <Experience />
        <Education />
      </Element>

      {/* <Resume /> */}
      <GithubBanner />

      <Element>
        {/* <Contact name="contact" /> */}
        <ContactComponent name="contact" />
      </Element>
    </div>
  );
};

export default Home;
