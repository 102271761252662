import React from 'react'
import MapComponent from "../component/_Map";
import Contact from "../component/_Contact";
import "../style/Contact.css";
import ContactInfo from "../component/_ContactInfo";

const ContactComponent = () => {
  return (
    <div>
      <div className="container Contact__detail my-14">
        {/* <h1>Resume</h1>   */}
        <h2>
          <span style={{ color: "#892CDC" }}>C</span>ontact
        </h2>
        <hr size="10" />
        <p>Please Explain Your Project Details To Us</p>

        <div className="my-8">
          <ContactInfo />
        </div>
      </div>

      {/* ============ MAP / CONTACT =========== */}
      <div class="container mx-auto p-4">
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/2 p-4 flex items-center">
            <MapComponent /> {/* COPONENT */}
          </div>

          <div class="w-full md:w-1/2 p-4">
            <Contact /> {/* COPONENT */}
          </div>
        </div>
      </div>
      {/* =========== MAP / CONTACT / END ============ */}
    </div>
  )
}

export default ContactComponent
