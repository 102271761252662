import React, { useState } from 'react';
import '../style/Portfolio.css';
import { portfolio } from '../data/constants';
import ReactPaginate from 'react-paginate';
import { Helmet } from 'react-helmet';

const Portfolio = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 8; // Adjust this value based on how many items you want per page

  const handleSearchChange = (event) => {
      setSearchQuery(event.target.value);
      setCurrentPage(0); // Reset to first page on search
  };

  // const filteredPortfolio = portfolio.filter(item =>
  //     item.WorkTitle.toLowerCase().includes(searchQuery.toLowerCase())
  // );

//   const filteredPortfolio = portfolio.filter(item =>
//     item.WorkTitle && item.WorkTitle.toLowerCase().includes(searchQuery.toLowerCase())
// );

console.log('Portfolio items:', portfolio);

const filteredPortfolio = portfolio.filter(item => {
  if (item && item.WorkTitle && typeof item.WorkTitle === 'string') {
    return item.WorkTitle.toLowerCase().includes((searchQuery || '').toLowerCase());
  } else {
    console.warn('Skipping item due to invalid WorkTitle:', item);
  }
  return false;
});


  const pageCount = Math.ceil(filteredPortfolio.length / itemsPerPage);

  const handlePageClick = (event) => {
      setCurrentPage(event.selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentItems = filteredPortfolio.slice(offset, offset + itemsPerPage);

  const truncateDescription = (description, wordLimit) => {
      const words = description.split(' ');
      if (words.length > wordLimit) {
          return words.slice(0, wordLimit).join(' ') + '...';
      }
      return description;
  };

  return (
    <>

<Helmet>
        <title>Portfolio - Suraj Shekhawat Software Development Projects</title>
        <meta name="description" content="Explore Suraj Shekhawat software development portfolio, showcasing a variety of projects including web development, mobile apps, and more." />
        <meta name="keywords" content="portfolio, Suraj Shekhawat, software developer, web development, mobile apps, projects" />
        <meta name="author" content="Suraj Shekhawat" />
        
        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://surajshekhawat.vercel.app/" />
        <meta property="og:title" content="Portfolio - Suraj Shekhawat Software Development Projects" />
        <meta property="og:description" content="Explore Suraj Shekhawat software development portfolio, showcasing a variety of projects including web development, mobile apps, and more." />
        <meta
          property="og:image"
          content="https://i.ibb.co/0sSkDvy/profile-img.png"
        />
        
        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://x.com/Suraj____07" />
        <meta property="twitter:title" content="Portfolio - Suraj Shekhawat Software Development Projects" />
        <meta property="twitter:description" content="Explore Suraj Shekhawat software development portfolio, showcasing a variety of projects including web development, mobile apps, and more." />
        <meta
          property="twitter:image"
          content="https://pbs.twimg.com/profile_images/1705881145257472000/mmr6cOKo_200x200.jpg"
        />
      </Helmet>

      {/* PORFOLIO BIO  */}
      <div className="portfolio__bio">
        <h1>Portfolio</h1>
        <p>I have worked on a wide range of projects. From web apps to android apps. Here are some of my projects.</p>
      </div>
      {/* PORTFOLIO BIO / END  */}

      {/* SEARCH BOX  */}
      <div className="portfolio__search">
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </div>
      {/* SEARCH BOX / END  */}

      {/* PORTFOLIO LIST  */}
      <div className="portfolio-grid">
        {currentItems.map(item => (
          <div className="portfolio-item" key={item.id}>
              <img src={item.WorkImage} alt={item.WorkTitle} />
            <h3>{item.WorkTitle}</h3>
            <p>{item.WorkType}</p>
            <p>{truncateDescription(item.WorkDescription, 20)}</p> {/* Set your desired word limit here */}
            <a href={item.Link} target="_blank" rel="noreferrer">View Project</a>
          </div>
        ))}
      </div>
      {/* PORTFOLIO LIST / END  */}
      <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        breakLabel={"..."}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
    </>
  );
};

export default Portfolio;
