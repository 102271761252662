import React from 'react';
import '../style/_Experience.css'

const Experience = () => {
  return (
    <div id='Experience' className='experience py-8'>
      <h2>Experience</h2>
      <hr />
      <div class="container mx-auto p-4">
        <div class="flex flex-wrap">
            <div class="w-full md:w-1/2 p-4">
                <div class="experience__card">
                    <h3 className="experience__date">6 Month Internship</h3>
                    <h3 className="experience__title">Web Developer</h3>
                    <h5 className="experience__company">APPINVENTERS</h5>
                    <p className="experience__description">Working on the frontend of the web application using ReactJS, Redux, Material UI, AngularJS and Wordpress</p>
                    <ul className="experience__exerptise">
                      <li>Working in developement of applications based on AngularJs and Typscript/Javascript.</li>
                      <li>I'm working with team on AngulaJs, ReactJs and Wordpress Website.</li>
                      <li>Developed a modular, single-page web application in
Angular that incorporated responsive design principles.</li>
                      <li>Implemented a responsive design that ensured the web
application was accessible on all devices.</li>
                    </ul>
                </div>
            </div>
            <div class="w-full md:w-1/2 p-4">
            <div class="experience__card">
                    <h3 className="experience__date">1 Year - Present</h3>
                    <h3 className="experience__title">Web Developer</h3>
                    <h5 className="experience__company">APPINVENTERS</h5>
                    <p className="experience__description">Working on the frontend of the web application using ReactJS, Redux, Material UI, AngularJS and Wordpress</p>
                    <ul className="experience__exerptise">
                      <li>I create many sucssesfull website with team and solo.</li>
                      <li>Write clean, efficient, and maintainable code adhering
to industry best practices.</li>
                      <li>Proficiency in AngularJS and WordPress development.</li>
                      <li>Working in developement of applications based on AngularJs and Typscript/Javascript.</li>
                      <li>Experience with other front-end frameworks and
libraries.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Experience
