import React from 'react';
import '../style/_Education.css';
import ResumeBtn from '../component/_ResumeBtn';

const Experience = () => {
  return (
    <div className='education py-8'>
      <h2>Education</h2>
      <hr />
      <div class="container mx-auto p-4">
        <div class="flex flex-wrap justify-center">
            <div class="w-full md:w-1/2 p-4">
                <div class="education__card">
                    <h3 className="education__date">2023-Present</h3>
                    <h3 className="education__title">Bachelor of Computer Applications</h3>
                    <h5 className="education__company">MAHARISHI DAYANAND SARSWATI COLLEGE</h5>
                    <p className="education__description">Grade: First class distinction.</p>
                </div>
            </div>
            {/* <div class="w-full md:w-1/2 p-4">
            <div class="education__card">
                    <h3 className="education__date">2020-2023</h3>
                    <h3 className="education__title">Bachelor of Computer Applications</h3>
                    <h5 className="education__company">HEMCHAND YADAV UNIVERSITY</h5>
                    <p className="education__description">Grade: First class distinction.</p>
                </div>
            </div> */}
        </div>
    </div>
    <ResumeBtn />
    </div>
  )
}

export default Experience
