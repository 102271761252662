import React from 'react';
import '../style/_header.css';
import  { useState, useEffect } from 'react';
import ThemeToggle from './ThemeToggle';
import { lightTheme, darkTheme } from '../theme';
import { FaXmark } from "react-icons/fa6";
import { FaBarsStaggered } from "react-icons/fa6";
// import { Link, animateScroll as scroll } from 'react-scroll';
import { Link as LinkTouch } from 'react-router-dom';
import Logotypewriter from './_LogoTyping';

const closeSideBar = () => {
    document.querySelector('.nav__item').style.transform = 'translateX(100%)';
    // document.querySelector('.nav__item').style.display = 'none';
}

const openSideBar = () => {
    // document.querySelector('.nav__item').style.display = 'block';
    document.querySelector('.nav__item').style.transform = 'translateX(0%)';
}

const Header = () => {

  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  useEffect(() => {
    const theme = isDarkMode ? darkTheme : lightTheme;
    for (const key in theme) {
      document.body.style.setProperty(`--${key}`, theme[key]);
    }
  }, [isDarkMode]);

  return (
    <header style={{ backgroundColor: 'var(--background)', color: 'var(--text)' }}>

    {/* ====== desktop header =========== */}
    <div className='desktop__header'>

      {/* ======== desktop logo ============ */}
      <div className="logo">
        {/* <h1>Suraj Shekhawat</h1> */}
        <Logotypewriter text="Code With Suraj" speed={100} loop={true} erasingSpeed={50} delay={2000}/>
      </div>
      {/* =========== end / desktop logo =========== */}

      {/* ========== desktop item ============ */}
      <div className="menu__item">

        <div className="items">
          <li><LinkTouch to={'/'} style={{cursor: 'pointer'}}>Home</LinkTouch></li>
          {/* <li><Link to='about' smooth={true} duration={500} style={{cursor: 'pointer'}}>About</Link></li> */}
          {/* <li><Link to='experience' smooth={true} duration={500} style={{cursor: 'pointer'}}>Resume</Link></li> */}
          <li><LinkTouch to={'portfolio'} style={{cursor: 'pointer'}}>Portfolio</LinkTouch></li>
          <li><LinkTouch to={'Blog'} style={{cursor: 'pointer'}}>Blogs</LinkTouch></li>
        </div>
        <div className="Two_btns">
          <LinkTouch to='contact-us' style={{cursor: 'pointer'}} className='contact__btn'><button>Contact Me</button></LinkTouch>
          {/* <LanguageSwitcher /> */}
          <ThemeToggle toggleTheme={toggleTheme} isDarkMode={isDarkMode} />
        </div>

      </div>
      {/* =========== end / desktop item =========== */}

    </div>
    {/* ========== end / desktop header ============ */}

    {/* ======= mobile header ========= */}
    <div className="mobile__header">

      {/* ========= mobile logo ========== */}
      <div className="mobile__logo">
        {/* <h1>Suraj Shekhawat</h1> */}
        <Logotypewriter text="Code With Suraj" speed={100} loop={true} erasingSpeed={50} delay={2000}/>
      </div>
      {/* ======= end / mobile logo ========== */}

      {/* ======== mobile navbar ========== */}
      <div className="mobile__nav">
      <ThemeToggle toggleTheme={toggleTheme} isDarkMode={isDarkMode} />
      <FaBarsStaggered className='mobile__icon text-2xl cursor-pointer'  onClick={openSideBar}/>
        
        {/* ======= hamburgur menu ======= */}
        <div className="mobile__item nav__item">
        <FaXmark className='mobile__icon my-4 mx-3 text-4xl cursor-pointer' onClick={closeSideBar} />
          <nav>
            <LinkTouch to={'/'} style={{cursor: 'pointer'}}>Home</LinkTouch>
            {/* <Link to='about' smooth={true} duration={500} style={{cursor: 'pointer'}}>About</Link> */}
            {/* <Link to='experience' smooth={true} duration={500} style={{cursor: 'pointer'}}>Resume</Link> */}
            <LinkTouch to={'portfolio'} style={{cursor: 'pointer'}}>Portfolio</LinkTouch>
            <LinkTouch to={'Blog'} style={{cursor: 'pointer'}}>Blogs</LinkTouch>
            <LinkTouch to={'contact-us'} style={{cursor: 'pointer'}}>Contact</LinkTouch>
          </nav>
        </div>
        {/* ======= end / hamburgur menu ======= */}

      </div>
      {/* ======== hamburgur menu ========== */}

    </div>
    {/* ========= end / mobile header ========== */}
    </header>
  )
}

export default Header;
