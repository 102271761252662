import {React, useState} from 'react'
import styled from 'styled-components';
import emailjs from "emailjs-com";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import '../style/_Contact.css'

const ContactSection = styled.section`
  width: 100%;
  height: auto;
  display: flex;
  margin: 2rem 0;
  padding: 0rem 1rem;

  form {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    text-align: left;
  }
`;

const FormFullWidth = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;

  textarea,
  select {
    padding: 0.5rem 0.8rem;
    color: var(--text);
    background: transparent;
    marign-top: 10px;
    border: 2px solid #8e05c2;
    border-radius: 5px;
  }

  option {
    padding: 0.5rem 0.8rem;
    color: #000;
    background: transparent;
    marign-top: 10px;
    border: 2px solid #8e05c2;
    border-radius: 5px;
  }

  .error {
    color: red;
    font-size: 0.8rem;
    font-weight: 400;
  }
`;

const FormLabel = styled.label`
  color: var(--text);
  font-size: 0.9rem;
  font-weight: 400;
`;

const FormInput = styled.input`
  padding: 0.5rem 0.8rem;
  background: transparent;
  marign-top: 10px;
  border: 2px solid #8e05c2;
  border-radius: 5px;
`;

const initialFormState = {
  fullName: "",
  email: "",
  phoneNumber: "",
  textarea: "",
  service: ""
};

const Contact = () => {

  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState({});

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.fullName.trim()) {
      errors.fullName = "Full name is required";
    }
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Invalid email format";
    }
    if (!formData.phoneNumber.trim()) {
      errors.phoneNumber = "Phone number is required";
    }
    if (!formData.textarea.trim()) {
      errors.textarea = "Message is required";
    }
    if (!formData.service.trim()) {
      errors.service = "Service is required";
    }
    return errors;
  };

  const handleSubmit = e => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    emailjs
      .send(
        "service_b6wk3ih",
        "template_v5i58vb",
        formData,
        "LaBah4wnqC6GqguRG"
      )
      .then(response => {
        toast.success("Email sent successfully");
        setFormData(initialFormState); // Reset form after successful submission
        setErrors({});
      })
      .catch(error => {
        console.error("Error sending email:", error);
        toast.error("Failed to send email");
      });
  };

  return (
    <>
      <ContactSection>
        <form onSubmit={handleSubmit}>
          <FormFullWidth>
            <FormLabel htmlFor="fullName">Full Name:</FormLabel>
            <FormInput
              type="text"
              id="fullName"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              placeholder="Enter a Name"
            />
            {errors.fullName &&
              <div className="error">
                {errors.fullName}
              </div>}
          </FormFullWidth>

          <FormFullWidth>
            <FormLabel htmlFor="email">Email:</FormLabel>
            <FormInput
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter a Email"
            />
            {errors.email &&
              <div className="error">
                {errors.email}
              </div>}
          </FormFullWidth>

          <FormFullWidth>
            <FormLabel htmlFor="phoneNumber">Phone Number:</FormLabel>
            <FormInput
              type="number"
              id="phoneNumber"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              placeholder="+91 000-0000-000"
            />
            {errors.phoneNumber &&
              <div className="error">
                {errors.phoneNumber}
              </div>}
          </FormFullWidth>

          <FormFullWidth>
            <FormLabel htmlFor="service">Service:</FormLabel>
            <select
              id="service"
              name="service"
              value={formData.service}
              onChange={handleChange}
            >
              <option value="">Select service</option>
              <option value="ReactJs Web Service">ReactJs Web Service</option>
              <option value="AngularJs Web Service">AngularJs Web Service</option>
              <option value="NextJs Web Service">NextJs Web Service</option>
              <option value="Wordpress Web Service">Wordpress Web Service</option>
              <option value="Shopify Web Service">Shopify Web Service</option>
              <option value="UI/UX Designer">UI/UX Designer</option>
              <option value="Graphic Designer">Graphic Designer</option>
              <option value="Facbook Ads">Facbook Ads</option>
              <option value="Video Editing">Video Editing</option>
            </select>
            {errors.service &&
              <div className="error">
                {errors.service}
              </div>}
          </FormFullWidth>

          <FormFullWidth>
            <FormLabel htmlFor="textarea">Message:</FormLabel>
            <textarea
              id="textarea"
              name="textarea"
              value={formData.textarea}
              onChange={handleChange}
              placeholder="Drop a Message"
            />
            {errors.textarea &&
              <div className="error">
                {errors.textarea}
              </div>}
          </FormFullWidth>

          <button type="submit" className="Contact__btn">Submit</button>
        </form>
        <ToastContainer
      position='top-left'
      autoClose= {2000}
      hideProgressBar= {false}
      newestOnTop= {false}
      closeOnClick
      rtl= {false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme='light'
      />
      </ContactSection>
    </>
  )
}

export default Contact
