// import React, { useState, useEffect } from 'react';
// import '../style/_LogoTyping.css';

// const Logotypewriter = ({ text, speed, loop = false }) => {
//   const [displayedText, setDisplayedText] = useState('');
//   const [index, setIndex] = useState(0);

//   useEffect(() => {
//     if (index < text.length) {
//       const timeout = setTimeout(() => {
//         setDisplayedText(prev => prev + text.charAt(index));
//         setIndex(index + 1);
//       }, speed);
//       return () => clearTimeout(timeout);
//     } else if (loop) {
//       // Reset after finishing if loop is enabled
//       const timeout = setTimeout(() => {
//         setDisplayedText('');
//         setIndex(0);
//       }, speed * 10); // Adjust delay before restarting loop
//       return () => clearTimeout(timeout);
//     }
//   }, [index, text, speed, loop]);

//   return <div className="typewriter">{displayedText}</div>;
// };

// export default Logotypewriter;

import React, { useState, useEffect } from 'react';
import '../style/_LogoTyping.css';

const Logotypewriter = ({ text, typingSpeed = 100, erasingSpeed = 50, delay = 2000, loop = false }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [index, setIndex] = useState(0);
  const [isErasing, setIsErasing] = useState(false);

  useEffect(() => {
    let timeout;

    if (!isErasing && index < text.length) {
      // Typing effect
      timeout = setTimeout(() => {
        setDisplayedText(prev => prev + text.charAt(index));
        setIndex(index + 1);
      }, typingSpeed);
    } else if (!isErasing && index === text.length) {
      // Pause before erasing
      timeout = setTimeout(() => {
        setIsErasing(true);
      }, delay);
    } else if (isErasing && index > 0) {
      // Erasing effect
      timeout = setTimeout(() => {
        setDisplayedText(prev => prev.slice(0, -1));
        setIndex(index - 1);
      }, erasingSpeed);
    } else if (isErasing && index === 0) {
      // Reset after erasing is complete
      if (loop) {
        setIsErasing(false);
      } else {
        clearTimeout(timeout); // Stop the loop if not looping
      }
    }

    return () => clearTimeout(timeout);
  }, [index, isErasing, text, typingSpeed, erasingSpeed, delay, loop]);

  return <div className="typewriter">{displayedText}</div>;
};

export default Logotypewriter;
