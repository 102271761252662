import React, { useState } from 'react';
import styled from 'styled-components';
import { BlogDetail as BlogData } from '../data/BlogData';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { useParams } from 'react-router-dom';
import Posts from '../pages/SinglePost';

const BlogHeader = styled.div`
 display: flex;
 flex-direction: column;
 width: 100%;


 h1 {
    text-align: center;
    font-size: 3rem;
    font-weight: 700;
    color: var(--text);
 }

 p {
  color: var(--text);
  text-align: center;
//   font-family: var(--text);
  margin: 5px 0;
  line-height: 36px
  font-size: 18px;
  
 }

 hr {
 width: 100px;
    position: relative;
    height: 4px;
    background-color: #892CDC;
    margin-bottom: 1rem;
    border: none;
    align-self: center;
    margin-top: 1rem;
 }
`

const BlogsCards = styled.div`
display: flex;
 flex-direction: row;
 flex-wrap: wrap;
 justify-content: start;
 align-items: center;

`

const Blog__Card = styled.div`
width: 380px;
margin: 10px;
padding: 15px;
background-color: var(--cardBg);
border-radius: 7px;
border: 1px solid #dfdfdf;
height: auto;
display: block;
overflow: hidden;
position: relative;
@media screen and (max-width: 680px) {
  width: 100% !important;
}
`

const Blog__Card__Image = styled.div`
width: 100%;
height: 200px;

h6{
  padding: 5px 10px;
//   font-family: var(--primary-text);
  background-color: #892CDC;
  color: white;
  width: max-content;
  border-radius: 28px;
  font-size: 0.6rem;
  position: absolute;
  top: 20px;
  right: 20px;
}
small {
  padding: 5px 10px;
//   font-family: var(--primary-text);
  background-color: #892CDC;
  color: white;
  width: max-content;
  border-radius: 28px;
  font-size: 0.6rem;
  position: absolute;
  top: 20px;
  left: 20px;
}

img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
`

const Blog__Card__Content = styled.div`
display: block;
text-align: left;

.Blog__Card__Title h3 {
//   font-family: var(--primary-text);
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
  color: #892CDC;
  margin: 10px 0;
}

.Blog__Card__Description p {
  font-size: 0.9rem;
//   font-family: var(--primary-text);
  color: var(--text);
  font-weight: 400;
  margin-top: 0.5rem;
  line-height: 1.5;
  word-wrap: break-word;
}

.Blog__Card__Description button {
  width: 100%;
  height: auto;
  margin: 0.5rem 0 0.5rem 0;
  padding: 5px 0;
  border-radius: 10px;
  background-color: #892CDC;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;
  transition: all .2s ease-in-out;

  &:hover, &:focus {
    outline: none;
    box-shadow: inset 0 0 0 2px #fff;
  }
}
`

const BlogFilter = styled.div`
display: flex;
 flex-direction: row;
 flex-wrap: wrap;
 justify-content: space-between;
 align-items: center;
 verticle-align: middle;
 width: 100%;
 margin: 2rem 0;

 @media (max-width: 480px) {
    justify-content: center !important;
  }

 input[type=text],
 input[type=text]:focus,
 input[type=text]:hover,
 input[type=text]:active{
  padding: 0.2rem 0.7rem;
  border: 1px solid #700b97;
  background: transparent;
  color: #892CDC;
  border-radius: 50px;
  margin: 0.5rem 0;
 }

 select {
  padding: 0.2rem 0.7rem;
  border: 1px solid #892CDC;
  border-radius: 7px;
  background: transparent;
  color: var(--text);
  cursor: pointer;
  margin: 0.5rem 0;
  text-align: center;
//   font-family: var(--primary-text);
  appearance: none;
  -webkit-appearance: none; /*For Chrome, Safari and Opera*/
  option {
    cursor: pointer;
   color: white !important;
   background-color: black!important;
   border: 1px solid #892CDC !important;
  }
 }
`

const Blog = () => {

  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('All');

  // Your existing code for rendering blogs
  const { postId } = useParams();

  return (
    <div className="py-6 sm:py-12 lg:container mx-auto px-4 overflow-x-hidden" >
      <Helmet>
        <title>Latest Blog Insight - AI Technology, Buisnesses, Trading</title>
        <meta name="description" content="Explore insightful articles, trends, and tips curated by our seasoned developers. Stay up-to-date with the ever-evolving world of mobile applications and learn .." />
        <meta property="og:title" content="Latest Blog Insight - AI Technology, Buisnesses, Trading, blogs" />
        <meta property="og:description" content="Explore insightful articles, trends, and tips curated by our seasoned developers. Stay up-to-date with the ever-evolving world of mobile applications and learn .." />
        {/* Add more meta tags as needed */}

        
      </Helmet>
      <BlogHeader>
      <h1>
          <span style={{ color: "#892CDC" }}>B</span>logs
        </h1>
        <hr size="10" />
        <p>Every Visitors See Latest News!</p>
        <Posts postId={postId} />

      </BlogHeader>

      <BlogFilter>

      {/* Filter Dropdown */}
      <select
        value={selectedFilter}
        onChange={(e) => setSelectedFilter(e.target.value)}
      >
        <option value="All">All</option>
        <option value="AI Technology">AI Technology</option>
        <option value="Buisness">Buisness</option>
        <option value="Trading">Trading</option>
        <option value="Technology">Technology</option>
        {/* Add more options as needed */}
      </select>

       {/* Search Bar */}
       <input
        type="text"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder="Search blogs..."
      />

      </BlogFilter>

      {/* Blog Cards */}
      <BlogsCards>
      {BlogData
          .filter(BlogData => selectedFilter === 'All' || BlogData.category === selectedFilter)
          .filter(BlogData => BlogData.SeoData.Title.toLowerCase().includes(searchQuery.toLowerCase()))
          .map(BlogData => {

            const truncatedContent = BlogData.Data.content.split(' ').slice(0,25).join(' ') + '...';
            const truncatedHeading = BlogData.Data.Heading.split(' ').slice(0,7).join(' ') + '...';

          return (
            <Blog__Card key={BlogData.id}>
              <Blog__Card__Image>
                <img src={BlogData.Data.ImgUrl} alt={BlogData.SeoData.Title} />
                <h6>{BlogData.category}</h6>
                <small>{BlogData.UploadDate}</small>
              </Blog__Card__Image>
              <Blog__Card__Content>
                <div className="Blog__Card__Title">
                  <h3>{truncatedHeading}</h3>
                </div>
                <div className="Blog__Card__Description">
                  <p >{truncatedContent}</p>
                  <button><Link to={`/blog/${BlogData.id}/${BlogData.SeoData.Title}`} style={{background: '#892CDC'}}>Read More</Link></button>
                </div>
              </Blog__Card__Content>
            </Blog__Card>
          )
        })}
      </BlogsCards>
    </div>
  )
}

export default Blog;


