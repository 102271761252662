// MapComponent.js
import React, { useEffect } from 'react';

function MapComponent() {
  return (
    <>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d892.7412856914382!2d74.67480749240735!3d26.489067161039657!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396be6724e0a31c9%3A0x131c7ada9af0b579!2sFMQG%2BR62%2C%20Kankarda%20Bhonabay%2C%20Rajasthan!5e0!3m2!1sen!2sin!4v1717004737917!5m2!1sen!2sin" width="100%" height="400px" style={{border:"0", borderRadius: "10px"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </>
  );
}

export default MapComponent;
