import React from 'react';
import { useParams } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { BlogDetail } from '../data/BlogData';
import { Helmet } from 'react-helmet';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const PostContainer = styled.div`
  animation: ${fadeIn} 0.5s ease-in-out;
  width: 1200px;
  max-width: 100%;
`;

const PostTitle = styled.h1`
  font-size: 2.2rem;
  color: #892CDC;
  font-weight: 800;
  margin-top: 1rem;
  font-family: var(--primary-text);

  @media screen and (max-width: 670px){
    font-size: 1.2rem;
  }
`;

const PostContent = styled.div`
  line-height: 1.6;
  color: #a1a1a1;
  text-align: left;

  h2{
    margin-top: 20px;
    color: var(--text);
    font-weight: 600;
    font-size: 1.8rem;
    font-family: var(--primary-text);
  }
  p{
    color: #a1a1a1;
    font-family: var(--primary-text);
  }

  ul, ol {
    margin: 20px 0;
    color: #a1a1a1;
    padding-left: 20px;
    list-style-type: disc;

    li {
      margin-bottom: 10px;
      color: #a1a1a1;
      font-family: var(--primary-text);
    }
  }

  pre {
    background: #e9e9e9;
    color: #a1a1a1;
    padding: 20px;
    border-radius: 5px;
    overflow-x: auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    font-size: 0.9rem;
    code {
      font-family: 'Courier New', Courier, monospace;
      background: #e9e9e9;
      color: #a1a1a1;
    }
    ::-webkit-scrollbar {
      height: 10px;
    }
    ::-webkit-scrollbar-track {
      background: #2e2e2e;
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb {
      background: #892CDC;
      border-radius: 5px;
    }
  }

  @media screen and (max-width: 670px){
    h2{
      font-size: 1rem;
    }
    p{
      font-size: 0.8rem;
    }
    ul {
      font-size: 0.8rem;
      li {
        font-size: 0.8rem;
      }
    }
  }
`;

const PostImage = styled.img`
  max-width: 100%;
  height: auto;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const PostPage = () => {
  const { postId } = useParams();
  const post = BlogDetail.find((post) => post.id === parseInt(postId));

  if (!post) {
    return <div></div>;
  }

  return (
    <PostContainer className='py-6 sm:py-12 lg:container mx-auto px-4'>
      <Helmet>
        <title>{post.SeoData.Title}</title>
        <meta name="description" content={post.SeoData.MetaDescription} />
        <meta property="og:title" content={post.SeoData.Title} />
        <meta property="og:description" content={post.SeoData.MetaDescription} />
        <meta property="og:image" content={post.Data.ImgUrl} />
        <meta name="keywords" content={post.SeoData.Keywords} />
      </Helmet>
        <PostImage src={post.Data.ImgUrl} alt={post.Data.Heading} />
      <PostTitle>{post.Data.Heading}</PostTitle>
      <PostContent>
        <h2>{post.Data.Introduction}</h2>
        <p>{post.Data.content}</p>
        <h2>{post.Data.SubHeadingOne}</h2>
        <p>{post.Data.ParagraphOne}</p>
        <ul>
          {(post.Data.BulletPoints || []).map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
        <h2>{post.Data.SubHeadingTwo}</h2>
        <p>{post.Data.ParagraphTwo}</p>
        <ul>
          {(post.Data.BulletPointsTwo || []).map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
        <h2>{post.Data.SubHeadingThree}</h2>
        <p>{post.Data.ParegraphThree}</p>
        <ol>
          {(post.Data.OrderedList || []).map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ol>
        <pre>
          <code>{post.Data.CodeExample}</code>
        </pre>
        <h2>{post.Data.SubHeadingFour}</h2>
        <p>{post.Data.ParegraphFour}</p>
        <ul>
          {(post.Data.BulletPointsThree || []).map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
        <h2>{post.Data.SubHeadingFive}</h2>
        <p>{post.Data.ParegraphFive}</p>
        <h2>{post.Data.SubHeadingSix}</h2>
        <p>{post.Data.ParegraphSix}</p>
        <h2>{post.Data.conclusion}</h2>
        <p>{post.Data.conclusionDetail}</p>
      </PostContent>
    </PostContainer>
  );
};

export default PostPage;



// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';

// const SinglePost = () => {
//     const { id } = useParams(); // Assuming you are using React Router
//     const [post, setPost] = useState(null);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);

//     useEffect(() => {
//         const fetchPost = async () => {
//             try {
//                 const response = await axios.get(`/api/post/${id}`);
//                 setPost(response.data);
//                 setLoading(false);
//             } catch (err) {
//                 setError(err.message);
//                 setLoading(false);
//             }
//         };

//         fetchPost();
//     }, [id]);

//     if (loading) {
//         return <div>Loading...</div>;
//     }

//     if (error) {
//         return <div>Error: {error}</div>;
//     }

//     return (
//         <div className="single-post">
//             {post && (
//                 <>
//                     <h1>{post.title}</h1>
//                     <p>{post.content}</p>
//                     <div>Written by: {post.author}</div>
//                     <div>Published on: {new Date(post.createdAt).toLocaleDateString()}</div>
//                 </>
//             )}
//         </div>
//     );
// };

// export default SinglePost;



// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import styled, { keyframes } from 'styled-components';
// import { Helmet } from 'react-helmet';

// const fadeIn = keyframes`
//   from {
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// `;

// const PostContainer = styled.div`
//   animation: ${fadeIn} 0.5s ease-in-out;
//   width: 1200px;
//   max-width: 100%;
// `;

// const PostTitle = styled.h1`
//   font-size: 2.2rem;
//   color: #892CDC;
//   font-weight: 800;
//   margin-top: 1rem;
//   font-family: var(--primary-text);

//   @media screen and (max-width: 670px){
//     font-size: 1.2rem;
//   }
// `;

// const PostContent = styled.div`
//   line-height: 1.6;
//   color: #a1a1a1;
//   text-align: left;
//   /* ... Additional styles as provided */
// `;

// const PostImage = styled.img`
//   max-width: 100%;
//   height: auto;
//   width: 100%;
//   border-radius: 5px;
//   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
// `;

// const SinglePost = () => {
//     const { id } = useParams();
//     const [post, setPost] = useState(null);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);

//     useEffect(() => {
//         const fetchPost = async () => {
//             try {
//                 const response = await axios.get(`/api/blogs/${id}`);
//                 setPost(response.data);
//                 setLoading(false);
//             } catch (err) {
//                 setError(err.message);
//                 setLoading(false);
//             }
//         };

//         fetchPost();
//     }, [id]);

//     if (loading) {
//         return <div>Loading...</div>;
//     }

//     if (error) {
//         return <div>Error: {error}</div>;
//     }

//     return (
//         <PostContainer className="py-6 sm:py-12 lg:container mx-auto px-4">
//             {post && (
//                 <>
//                     <Helmet>
//                         <title>{post.title}</title>
//                         <meta name="description" content={post.description} />
//                         <meta property="og:title" content={post.title} />
//                         <meta property="og:description" content={post.description} />
//                         <meta property="og:image" content={post.featuredImage} />
//                         <meta name="keywords" content={post.keywords} />
//                     </Helmet>
//                     <PostImage src={post.featuredImage} alt={post.title} />
//                     <PostTitle>{post.title}</PostTitle>
//                     <PostContent dangerouslySetInnerHTML={{ __html: post.content }} />
//                 </>
//             )}
//         </PostContainer>
//     );
// };

// export default SinglePost;