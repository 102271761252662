import React from "react";
import "../style/_HeroComponent.css";
// import ProfileImg from '../ImportedPhoto_1717785659961.jpg'

var TxtType = function(el, toRotate, period) {
  this.toRotate = toRotate;
  this.el = el;
  this.loopNum = 0;
  this.period = parseInt(period, 10) || 2000;
  this.txt = '';
  this.tick();
  this.isDeleting = false;
};

TxtType.prototype.tick = function() {
  var i = this.loopNum % this.toRotate.length;
  var fullTxt = this.toRotate[i];

  if (this.isDeleting) {
  this.txt = fullTxt.substring(0, this.txt.length - 1);
  } else {
  this.txt = fullTxt.substring(0, this.txt.length + 1);
  }

  this.el.innerHTML = '<span class="wrap">'+this.txt+'</span>';

  var that = this;
  var delta = 200 - Math.random() * 100;

  if (this.isDeleting) { delta /= 2; }

  if (!this.isDeleting && this.txt === fullTxt) {
  delta = this.period;
  this.isDeleting = true;
  } else if (this.isDeleting && this.txt === '') {
  this.isDeleting = false;
  this.loopNum++;
  delta = 500;
  }

  setTimeout(function() {
  that.tick();
  }, delta);
};

window.onload = function() {
  var elements = document.getElementsByClassName('typewrite');
  for (var i=0; i<elements.length; i++) {
      var toRotate = elements[i].getAttribute('data-type');
      var period = elements[i].getAttribute('data-period');
      if (toRotate) {
        new TxtType(elements[i], JSON.parse(toRotate), period);
      }
  }
  // INJECT CSS
  var css = document.createElement("style");
  css.type = "text/css";
  css.innerHTML = ".typewrite > .wrap { border-right: 0.08em solid #fff}";
  document.body.appendChild(css);
};

const HeroComponent = () => {
  return (
    <div id="Home" className="hero__component container-lg">
      <div className="left__side">
        <div className="bio__div">
          <h6>HELLO!</h6>
          <h2>
            I'm <span style={{
              color: '#892CDC'
            }}>Suraj Singh<br/> Shekhawat</span>
          </h2>
        </div>

        <div className="typing__animation">
          <h3 class="typewrite" data-period="2000" data-type='[ "Software Developer", "Freelancer", "Web Developer" ]'>
            <span class="wrap" style={{color: 'var(--text)'}}/>
          </h3>
        </div>

        <div className="hero__btn">
          <a href="https://github.com/SurajShekhawat">
            <button className="github__btn">My Github</button>
          </a>
          <a href="https://www.linkedin.com/in/suraj-shekhawat-a5ba06218/">
            <button className="linkedin__btn">My Linkedin</button>
          </a>
        </div>
      </div>

      <div className="right__side">
        <div className="profile__div__shape">
        <img src="https://i.postimg.cc/HL8HCZbh/Imported-Photo-1717785659961.jpg"  alt="profile-img" srcset="" />
        </div>
      </div>
    </div>
  );
};

export default HeroComponent;
