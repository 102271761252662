import React from 'react'
import styled from 'styled-components';
import { FaLinkedinIn, FaInstagram, FaGithub, FaHackerrank } from 'react-icons/fa6';

const BottomNavContainer = styled.div`
  background-color: transparent;
// background-color: blue;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  position: sticky;
  bottom:0;
  z-index: 999;
  `

  const Bottom__Nav = styled.div`
  display: flex;
  padding: 0.7rem 1rem;
  margin: 10px;
  background: white;
  border-radius: 25px;
  border: 0.5px solid;
    border-color: #dbdbdb;
    gap: 24px;
    background: var(--background)
  `

const BottomNav = () => {
  return (
    <BottomNavContainer className='container'>
        <Bottom__Nav>
            <a href="https://www.linkedin.com/in/suraj-shekhawat-a5ba06218/" target='_blank'>
            <FaLinkedinIn 
            style={{
                color: 'white', 
                fontSize: '32px',
                cursor: 'pointer',
                padding: '6px',
                background: '#892CDC',
                borderRadius: '50%',
                }}/>
            </a>

            <a href="https://www.instagram.com/suraj_rajput_1.8/" target='_blank'>
            <FaInstagram
            style={{
                color: 'white', 
                fontSize: '32px',
                cursor: 'pointer',
                padding: '6px',
                background: '#892CDC',
                borderRadius: '50%',
                }}
            />
            </a>

            <a href="https://github.com/SurajShekhawat" target='_blank'>
            <FaGithub
            style={{
                color: 'white', 
                fontSize: '32px',
                cursor: 'pointer',
                padding: '6px',
                background: '#892CDC',
                borderRadius: '50%',
                }}
            />  {/* Add your own icons here */}
            </a>

            <a href="https://www.hackerrank.com/profile/shekhawatsuraj21" target='_blank'>
            <FaHackerrank
            style={{
                color: 'white', 
                fontSize: '32px',
                cursor: 'pointer',
                padding: '6px',
                background: '#892CDC',
                borderRadius: '50%',
                }}
            /> {/* Add your own icons here */}
            </a>
        </Bottom__Nav>
    </BottomNavContainer>
  )
}

export default BottomNav;
