import React from 'react';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import '../style/_ContactInfo.css';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const ContactInfo = () => {
  return (
    <>
      {/* <!-- Container --> */}
    <div class="container mx-auto">

        {/* <!-- Grid Container --> */}
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">

            {/* <!-- Div 1 --> */}
            <div class="Contact__Icon__Div">
              <h2>
                <FmdGoodIcon className='icon' />
              </h2>
                <p class="text-gray-700 text-center">Karkriya Bhuna Bai, Jaipur Road, Ajmer (Rajasthan)</p>
            </div>

            {/* <!-- Div 2 --> */}
            <div class="Contact__Icon__Div">
                <h2>
                  <LocalPhoneIcon className='icon' />
                </h2>
                <a href="tel:+91 6378953515">
                <p class="text-gray-700 text-center">(+91) 637-8953-515</p>
                </a>
            </div>

            {/* <!-- Div 3 --> */}
            <div class="Contact__Icon__Div">
                <h2>
                <EmailIcon className='icon' />
                </h2>
                <a href="mailto:shekhawatsuraj202@gmail.com"><p class="text-gray-700 text-center">shekhawatsuraj202@gmail.com</p>
                </a>
            </div>

            {/* <!-- Div 4 --> */}
            <div class="Contact__Icon__Div">
                <h2>
                <FileDownloadIcon className='icon' />
                </h2>
                <a href="https://shekhawatsuraj21.hackerresume.io/00fee03b-8cad-4862-b92b-c33ede3e8304">
                <p class="text-gray-700 text-center">Download CV</p>
                </a>
            </div>

        </div>
    </div>
    </>
  )
}

export default ContactInfo
