import React from 'react';
import '../style/_AboutMe.css';
import LazyLoad from 'react-lazyload';

const AboutMe = () => {
  return (
    <div id="About" className='py-14'>
      <div class="container mx-auto p-4">
        <div class="flex flex-wrap">
            <div class="w-full md:w-1/2 p-4">
                <div className="block">

                  {/* ============= PROFILE INFO ============== */}
                  <div className=" profile__div">
                    <div className="profile_img md:none sm:text-center">
                    <LazyLoad height={200} offset={100}>
                      <img src="https://i.postimg.cc/kD3QsXWy/Imported-Photo-1717786595475.jpg" alt="suraj-shekhawat" />
                    </LazyLoad>
                    </div>
                    <div className="profile_info">
                      <p><strong>Name:&nbsp;</strong> Suraj Singh Shekhawat</p>
                      <p><strong>Job Role:&nbsp;</strong> Software Developer</p>
                      <p><strong>Location:&nbsp;</strong> Ajmer, Rajasthan</p>
                    </div>
                  </div>
                  {/* ================ PROFILE INFO / END ============== */}

                  {/* ============= PROGRESS BAR ============== */}
                  <div class="skill-mf">
                    <p class="title-s">Skills</p>
                    <span>Flutter</span>
                    {/* <!-- <span class="pull-right">85%</span> --> */}
                    <div class="progress">
                      <div class="progress-bar" role="progressbar" style={{width: "99%"}} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>

                    <span>SQL</span>
                    {/* <!-- <span class="pull-right">95%</span> --> */}
                    <div class="progress">
                      <div class="progress-bar" role="progressbar" style={{width: "99%"}} aria-valuenow="95" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>

                    <span>PYTHON</span>
                    {/* <!-- <span class="pull-right">85%</span> --> */}
                    <div class="progress">
                      <div class="progress-bar" role="progressbar" style={{width: "99%"}} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>

                    <span>Wordpress</span>
                    {/* <!-- <span class="pull-right">99%</span> --> */}
                    <div class="progress">
                      <div class="progress-bar" role="progressbar" style={{width: "99%"}} aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>

                    <span>Javascript</span>
                    {/* <!-- <span class="pull-right">99%</span> --> */}
                    <div class="progress">
                      <div class="progress-bar" role="progressbar" style={{width: "99%"}} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>

                    <span>C++</span>
                    {/* <!-- <span class="pull-right">99%</span> --> */}
                    <div class="progress">
                      <div class="progress-bar" role="progressbar" style={{width: "99%"}} aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                  {/* ============= PROGRESS BAR / END ============== */}

                </div>
            </div>
            <div class="w-full md:w-1/2 p-4">
                <div className="aboutme__content">
                  <h2><span style={{color: '#892CDC'}}>A</span>bout Me</h2>
                  <hr />
                  <br />
                  <p>I am a motivated and versatile individual, always eager to take on new challenges. With a passion for learning I am dedicated to delivering high-quality results. With a positive attitude and a growth mindset, I am ready to make a meaningful contribution and achieve great things.</p>
                  <br />

                  <p><strong>Profile: &nbsp;&nbsp;&nbsp;</strong>FullStack Developer & Freelancer</p>
                  <br />
                  <p><strong>Domain: &nbsp;&nbsp;&nbsp;</strong>Software Developement</p>
                  <br />
                  <p><strong>Education: &nbsp;&nbsp;&nbsp;</strong>Bachelor of Computer Applications</p>
                  <br />
                  <p><strong>Language: &nbsp;&nbsp;&nbsp;</strong>English, Hindi</p>
                  <br />
                  <p><strong>Other Skills: &nbsp;&nbsp;&nbsp;</strong>Creativity, Communication skills, Time management, Adaptability & Attention to detail</p>
                  <br />
                  <p><strong>Interest: &nbsp;&nbsp;&nbsp;</strong>Traveling, Travel Photography, Teaching, Editing</p>
            
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default AboutMe
