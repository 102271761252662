// import { hover } from "@testing-library/user-event/dist/hover";
import React from "react";

const GithubBtn = () => {
  return (
    <a href="https://github.com/SurajShekhawat" target="_blank" style={{
      background: 'transparent'
    }}>
      <button
      style={{
        color: "white",
        backgroundColor: "#892CDC",
        border: "none",
        borderRadius: "25px",
        padding: "0.6rem 1.8rem",
        fontSize: "15px",
        cursor: "pointer",
        fontWeight: "600",
        boxShadow: "0 0 10px rgba(0,0,0,0.2)",
        transition: "all 0.2s ease-in-out",
      }}
      >Github</button>
      </a>
  );
};

export default GithubBtn;
